import { useState } from 'react'
import useLockBodyScroll from './useLockBodyScroll'

export default function useManagedLockBodyScroll() {
  const [locked, setLocked] = useState(false)
  useLockBodyScroll(locked)

  const lock = () => setLocked(true)
  const unlock = () => setLocked(false)

  return [lock, unlock] as const
}
