import { useEffect } from 'react'

export default function useLockBodyScroll(locked: boolean) {
  useEffect(() => {
    // Get original body overflow
    const originalStyle = 'hidden visible'
    // Prevent scrolling on mount
    if (locked) {
      // ? Prevents jumping of content because of scroll bar disappearing
      document.body.style.setProperty('--scrollbar-width', `${window.innerWidth - document.body.clientWidth}px`)
      document.body.style.overflow = 'hidden'
      document.body.style.touchAction = 'none'
    }
    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.setProperty('--scrollbar-width', '0px')
      document.body.style.overflow = originalStyle
      document.body.style.touchAction = 'auto'
    }
  }, [locked])
}
