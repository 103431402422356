import { createContext } from 'react'

export interface ContextType {
  logoColor: string
  setLogoColor: (color: string) => void
}

const sharedState = {
  logoColor: 'fresh-mint',
  setLogoColor: () => sharedState.logoColor
}

const AppContext = createContext<ContextType>(sharedState as ContextType)

export default AppContext
