import React, { useState, useEffect } from 'react'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Head from 'next/head'
import Script from 'next/script'

import { GraphQLClient, ClientContext } from 'graphql-hooks'
import memCache from 'graphql-hooks-memcache'

// Components
import TransitionLayout from '@/components/meta/Layout'
import MainNav from '@/components/organisms/MainNav'
import Footer from '@/components/organisms/Footer'
import CookieNotice from '@/components/organisms/CookieNotice'
import Cursor from '@/components/atoms/Cursor'

// Data
import { footerData } from '@/components/organisms/Footer/data'

// Lib
import AppContext from '@/lib/context'
import { PositionProvider } from '@/lib/fixedMarkContext'

// Hooks
import { useCookieConsent, useMediaQuery } from '@/hooks'

// Styling
import '../styles/master.scss'
import FixedYummygumMark from '@/components/atoms/FixedYummygumMark'

const client = new GraphQLClient({
  url: 'https://graphql.datocms.com/',
  headers: {
    authorization: `Bearer ${process.env.NEXT_PUBLIC_DATOCMS_API_TOKEN}`
  },
  cache: memCache()
})

export default function MyApp({ Component, pageProps }: AppProps) {
  const isReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)')
  const isTouchDevice = useMediaQuery('(pointer: coarse), (pointer: none)')

  const [
    allowsCookies,
    hasDecidedConsent,
    acceptCookies,
    denyCookies
  ] = useCookieConsent()
  const router = useRouter()

  // Check if footer should be compact on particular routes
  const isCompact = router.route === '/projects/[slug]' || router.route === '/404' || router.route === '/contact/sent'

  // Check if header should be dark on particular routes
  const isDark = (router.asPath.startsWith('/services/') && router.asPath !== '/services/')

  const [logoColor, setLogoColor] = useState('fresh-mint')

  const toggleLogoColor = (color: string) => {
    setLogoColor(color)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // @ts-expect-error this is fine, we set a global variable this way
      window.globalThis.allowsCookies = allowsCookies || false
    }
  }, [allowsCookies])

  return (
    <ClientContext.Provider value={client}>
      <AppContext.Provider value={{
        logoColor,
        setLogoColor: toggleLogoColor
      }}
      >
        <PositionProvider>
          {
            !hasDecidedConsent && (
              <CookieNotice
                onAccept={acceptCookies}
                onDeny={denyCookies}
              />
            )
          }
          <Head>
            <meta content="width=device-width, initial-scale=1.0, viewport-fit=cover" name="viewport" />
            <Script src="https://dev.yummygum.dev/markerio?applicationToken=62fb877fa52f1832e1d3392e" />
          </Head>
          <MainNav isDark={isDark} />
          <FixedYummygumMark />
          <TransitionLayout>
            <div id="modal-root" />
            <Component {...pageProps} />
            <Footer {...footerData} isCompact={isCompact} />
            <Script dangerouslySetInnerHTML={{
              __html: ` (function(w,d,s,l,i) {
                w[l] = w[l] || [];
                w[l].push({
                  'gtm.start': new Date().getTime(),event:'gtm.js'
                });
                var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                j.async=true;
                j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-WXMQRC6');`
            }}
            />
          </TransitionLayout>
          {(!isReducedMotion && !isTouchDevice) && (
            <Cursor />
          )}

        </PositionProvider>
      </AppContext.Provider>
    </ClientContext.Provider>
  )
}
