import React, { useEffect } from 'react'
import {
  LazyMotion,
  m,
  domAnimation,
  useReducedMotion,
  useAnimationControls
} from 'framer-motion'

// Styling
import styles from './Banner.module.scss'

// Types
interface IShopIllustrationProps {
  start?: boolean
  className?: string
}

const ShopIllustration: React.FC<IShopIllustrationProps> = ({
  start,
  className = ''
}) => {
  const prefersReducedMotion = useReducedMotion()

  const door = useAnimationControls()
  const hood = useAnimationControls()

  useEffect(() => {
    // Start animations
    if (start) {
      door.start({ rotateY: 0 })
      hood.start({ clipPath: 'polygon(43% 0, 0 50%, 0 63%, 57% 100%, 57% 87%, 100% 37%)' })
    } else {
      // Reset animation after bubble is closed
      setTimeout(() => {
        door.set({ rotateY: 160 })
        hood.set({ clipPath: 'polygon(43% 0, 0 10%, 0 10%, 100% 77%, 100% 77%, 100% 37%)' })
      }, 1000)
    }
  }, [
    start,
    door,
    hood
  ])

  return (
    <svg
      className={className}
      fill="none"
      height="48"
      viewBox="0 0 45 48"
      width="45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M25.2422 23.3679L5.70312 11.7531L25.3779 0L44.9172 11.7531L25.2422 23.3679Z" fill="#B1FCFB"/>
      <path d="M25.242 23.3678L5.70293 11.7529L5.4519 36.2307L25.242 47.9109V23.3678Z" fill="#94FAF8"/>
      <path d="M44.9169 36.2276L25.2419 47.9181V23.3682L44.9169 11.7534V36.2276Z" fill="#51F0ED"/>
      <path d="M8.32812 18.4414L20.9772 25.8479V36.6464L8.32812 29.0008V18.4414Z" stroke="#11171D" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>

      <LazyMotion features={domAnimation}>
        {/* Animating the hood to 'slide out' */}
        <m.g
          animate={prefersReducedMotion ? '' : hood}
          initial={{ clipPath: 'polygon(43% 0, 0 10%, 0 10%, 100% 77%, 100% 77%, 100% 37%)' }}
          transition={{
            duration: 1.5,
            delay: 0.75,
            ease: 'easeInOut'
          }}
        >
          <path d="M20.2904 26.2993L16.3709 23.9584L7.72534 32.5835L11.698 35.1164L20.2904 26.2993Z" fill="#B1FCFB"/>
          <path d="M16.4044 23.9782L12.6013 21.749L4.16992 30.5909L8.1426 33.1238L16.4044 23.9782Z" fill="#51F0ED"/>
          <path d="M12.7072 21.8133L9.18295 19.7266L0.585938 28.5014L4.16938 30.6612L12.7072 21.8133Z" fill="#B1FCFB"/>
          <path d="M0.597656 28.4976V30.7251L4.23969 32.7705V30.6254L0.597656 28.4976Z" fill="#68F5F3"/>
          <path d="M8.29286 32.9536L8.2832 35.0902L11.7751 37.169V35.0239L8.29286 32.9536Z" fill="#68F5F3"/>
          <path d="M4.23438 30.6133L4.23828 32.7796L8.29351 35.0904V32.9453L4.23438 30.6133Z" fill="#33E3E0"/>
        </m.g>
      </LazyMotion>

      <path d="M37.5667 39.9031C37.4004 36.9909 37.9527 35.7742 37.5667 24.5098C37.5667 24.5098 31.2275 27.8835 31.6406 28.2165C31.2275 33.2155 31.6406 43.3689 31.6406 43.3689" stroke="#11171D" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>

      <LazyMotion features={domAnimation}>
        {/* Animating the door to 'open' */}
        <m.g
          animate={prefersReducedMotion ? '' : door}
          className={styles.door}
          initial={{ rotateY: 160 }}
          transition={{
            duration: 1,
            delay: 2.25,
            ease: 'easeInOut'
          }}
        >
          <path d="M37.5732 39.9031C37.3765 36.9909 38.0297 35.7742 37.5732 24.5098C37.5732 24.5098 43.5118 27.439 44 27.7721C43.5118 32.7711 44 42.9245 44 42.9245L37.5732 39.9031Z" stroke="#11171D" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
          <path d="M41.4576 35.504C40.9016 35.2274 40.4508 34.5547 40.4508 34.0015C40.4508 33.4482 40.9016 33.224 41.4576 33.5006C42.0136 33.7772 42.4644 34.4499 42.4644 35.0031C42.4644 35.5564 42.0136 35.7806 41.4576 35.504Z" fill="#11171D"/>
        </m.g>
      </LazyMotion>
    </svg>
  )
}

export default React.memo(ShopIllustration)
