import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'

// Types
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any
  }
}

const latestConsentVersion = '2'

const revokeConsentIfNotLatest = () => {
  const consentVersion = Cookies.get('consentVersion')

  if (consentVersion !== latestConsentVersion) {
    // Remove all cookies
    Cookies.remove('cookieConsent')
    Cookies.remove('hasDecidedConsent')
    Cookies.remove('consentVersion')
  }
}

/**
 * Hook for handling the giving of consent, getting of consent and rejecting of consent.
 *
 * @returns {[boolean, boolean, () => void, () => void]} AllowsCookies, hasDecidedConsent, acceptCookies, denyCookies
 */
export default function useCookieConsent(): [boolean, boolean, () => void, () => void] {
  const [hasDecidedConsent, setHasDecidedConsent] = useState(true)
  const [allowsCookies, setAllowsCookies] = useState(false)

  useEffect(() => {
    revokeConsentIfNotLatest()

    const cookieConsent = Cookies.get('cookieConsent')
    const hasGivenConsent = Cookies.get('hasDecidedConsent')

    if (cookieConsent === 'false' || cookieConsent === undefined) {
      setAllowsCookies(false)
    }

    if (cookieConsent === 'true') {
      setAllowsCookies(true)
    }

    if (hasGivenConsent === undefined) {
      setHasDecidedConsent(false)
    }

    if (hasGivenConsent !== undefined) {
      setHasDecidedConsent(true)
    }
  }, [])

  // Update the value of cookie consent
  const setValue = (value: boolean): void => {
    // Set local state
    setAllowsCookies(value)
    Cookies.set('cookieConsent', `${value}`, { expires: 60 })

    setHasDecidedConsent(true)
    Cookies.set('hasDecidedConsent', 'true', { expires: 60 })

    Cookies.set('consentVersion', latestConsentVersion, { expires: 60 })
  }

  const handleAllow = (): void => {
    setValue(true)

    if (window.dataLayer) {
      // GTM event trigger to track cookie consent
      window.dataLayer.push({
        event: 'CookieConsentValueChange'
      })
    }
  }

  const handleReject = (): void => {
    setValue(false)
  }

  return [
    allowsCookies,
    hasDecidedConsent,
    handleAllow,
    handleReject
  ]
}
