import { gql } from '@/lib/api/gql'

export const baseImageFragment = gql`
  fragment baseImageFragment on FileField {
    url
    alt
    width
    height
    format
    responsiveImage {
      ...responsiveImageFragment
    }
    # muxVideoFragment broke when used here as such I made the decision to just get the source url
    video { src: mp4Url }
  }
`
