import { gql } from '@/lib/api/gql'

export const ALL_ACTIVE_CAREERS_QUERY = gql`
  query AllActiveCareersQuery {
    openings: allCareers(filter: {hasJobOpening: {eq: "true"}}) {
      title
      slug
      description: excerpt
      hasJobOpening
      rootPath
    }
  }
`
