/**
 * Method that returns the name of the month based on the current date and the number of weeks from now
 * @param weekCount Number of weeks from now
 */
const getMonthNameWeeksFromNow = (weekCount = 4) => {
  const date = new Date()

  date.setDate(date.getDate() + weekCount * 7)
  const month = date.toLocaleString('en-US', { month: 'long' })

  return month
}

export default getMonthNameWeeksFromNow
