import React, { useEffect } from 'react'
import {
  LazyMotion,
  m,
  domAnimation,
  useReducedMotion,
  useAnimationControls
} from 'framer-motion'

// Types
interface ISquiggleProps {
  start?: boolean
  className?: string
}

const transition = {
  duration: 0.75,
  ease: 'easeInOut',

  /*
   * Both 'stops' take up 10% of the animation time, which means the three animations itself are
   * 80 / 3 = 26.7% each. The duration can change, but the relative values stay the same
   */
  times: [
    0,
    0.267,
    0.367,
    0.634,
    0.734,
    1
  ]
}

const Squiggle: React.FC<ISquiggleProps> = ({
  start,
  className = ''
}) => {
  const prefersReducedMotion = useReducedMotion()

  const drawing = useAnimationControls()

  useEffect(() => {
    if (start) {
      drawing.start({
        pathLength: [
          0,
          0.43,
          0.45,
          0.8,
          0.82,
          1
        ]
      })
    } else {
      // Reset animation after bubble is closed
      drawing.set({ pathLength: 0 })
    }
  }, [start, drawing])

  return (
    <svg
      className={className}
      fill="none"
      height="10"
      viewBox="0 0 78 10"
      width="78"
      xmlns="http://www.w3.org/2000/svg"
    >
      <LazyMotion features={domAnimation}>
        <m.path
          animate={prefersReducedMotion ? '' : drawing}
          d="M1.27997 1.1153C20.5897 2.70245 76.5734 1.75243 76.5734 1.75243C60.482 6.53655 22.82 6.26191 15.6134 5.90201C6.60516 5.45214 39.7619 9.62154 50.6407 8.78106"
          fill="transparent"
          initial={{ pathLength: 0 }}
          stroke="#18DCD9"
          strokeDasharray="0"
          strokeWidth="2"
          transition={transition}
        />
      </LazyMotion>
    </svg>
  )
}

export default React.memo(Squiggle)

