import { baseImageFragment } from '@/lib/api/fragments'
import { gql } from '@/lib/api/gql'

export const blogPostFragment = gql`
  fragment blogPostFragment on BlogPostRecord {
    thumbnail {
      ...baseImageFragment
      responsiveImage {
        ...responsiveImageFragment
      }
    }
    thumbnailLoadingColor {
      hex
    }
    slug
    author {
      quote
      name
      id
      title
      avatar {
        ...baseImageFragment
        responsiveImage {
          ...responsiveImageFragment
        }
      }
    }
    categories {
      category
    }
    title
    id
    date
  }

  ${baseImageFragment}
`
