import * as React from 'react'
import Link from 'next/link'

// Context
import { usePosition } from '@/lib/fixedMarkContext'

// Styles
import styles from './FixedYummygumMark.module.scss'

const FixedYummygumMark: React.FC = () => {
  const { position } = usePosition()

  return (
    <Link href="/" prefetch={false}>
      <a
        className={`${styles.logo} cursor-element`}
        style={{
          '--left': `${position.x}px`,
          opacity: position.x > 0 ? 1 : 0
        } as React.CSSProperties}
      >
        <img
          alt=""
          src="/img/logo/logo-mark-white.png"
        />
      </a>
    </Link>
  )
}

export default FixedYummygumMark
