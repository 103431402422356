import * as React from 'react'

// Types
interface IAnimateMarkProps {
  isEntering: boolean
  isExiting: boolean
  height?: number
  width?: number
  className?: string
}

/**
 * Animated mark component, this is used to animate the Mark.
 * Currently it's only used in the MainNav component.
 *
 * This will be used for onHover on our logo in the future.
 * However currently the render isn't the exact color so this is very notable.
 * As such in this [thread](https://yummygum.slack.com/archives/C01A0P0L09K/p1641910725156000) it has been decided to only use it in MainNav for now.
 */
function AnimatedMark({
  isEntering,
  isExiting,
  height = 41,
  width = 44,
  className = ''
}: IAnimateMarkProps) {
  const markRef = React.useRef<HTMLVideoElement>(null)

  const enter = () => {
    if (markRef.current) {
      markRef.current.currentTime = 0
      markRef.current.play()

      setTimeout(() => {
        markRef.current?.pause()
        if (markRef.current) {
          markRef.current.currentTime = 1.5
        }
      }, 1500)
    }
  }

  const exit = () => {
    if (markRef.current) {
      markRef.current.currentTime = 1.5
      markRef.current.play()
    }
  }

  React.useEffect(() => {
    if (isEntering) {
      enter()
    }
  }, [isEntering])

  React.useEffect(() => {
    if (isExiting) {
      exit()
    }
  }, [isExiting])

  return (
    <video
      className={className}
      disableRemotePlayback
      height={height}
      muted
      playsInline
      preload="auto"
      ref={markRef}
      width={width}
    >
      <source
        src="/video/logo/mark.mp4"
        type='video/mp4; codecs="hvc1"'
      />
      <source
        src="/video/logo/mark.webm"
        type="video/webm"
      />
    </video>
  )
}

export default AnimatedMark
