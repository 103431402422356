import { gql } from '@/lib/api/gql'

export const BANNER_QUERY = gql`
  query BannerQuery {
    banner: announcementBanner {
      title
      description
      weekCount
      isShown
    }
  }
`
