import * as React from 'react'
import RawButton from '../RawButton'

const Button: React.ForwardRefRenderFunction<HTMLElement, ButtonProps> = ({
  type = 'button',
  children,
  label = '',
  ...props
}, ref) => {
  return (
    <RawButton
      {...props}
      aria-label={label}
      as="button"
      ref={ref}
      type={type}
    >
      {children}
    </RawButton>
  )
}

export default React.forwardRef(Button)
