import type { Variants } from 'framer-motion'

// Constants
import { durationFast, ease } from '@/constants/animations'

const carousel: Variants['carousel'] = {
  width: 80,
  height: 80,
  background: '#fff',
  border: '2px solid #FFFFFF00',
  borderRadius: 40,
  top: 0,
  scale: 1,
  transition: {
    ease: ease,
    duration: durationFast
  }
}

const variants: Variants = {
  default: {
    width: 24,
    height: 24,
    background: '#fff',
    border: '2px solid #FFFFFF00',
    borderRadius: 12,
    top: 0,
    scale: 1,
    transition: {
      ease: ease,
      duration: durationFast
    }
  },
  circle: {
    willChange: 'transform, background, border, border-radius, scale',
    width: 24,
    height: 24,
    background: '#FFFFFF00',
    border: '1px solid #FFFFFF',
    borderRadius: 12,
    scale: 2.34,
    top: 0,
    transition: {
      ease: ease,
      duration: durationFast
    }
  },
  click: {
    width: 24,
    height: 24,
    background: '#fff',
    border: '2px solid #FFFFFF00',
    borderRadius: 12,
    scale: 0.6,
    top: 0,
    transition: {
      ease: ease,
      duration: durationFast
    }
  },
  line: {
    width: 4,
    height: 24,
    // Position the line in the middle of the cursor
    left: 10.6,
    top: 0,
    background: '#fff',
    borderRadius: 2,
    scale: 1,
    transition: {
      ease: ease,
      duration: 0.1
    }
  },
  lineClick: {
    width: 4,
    height: 24,
    // Position the line in the middle of the cursor
    left: 10.6,
    top: 0,
    background: '#fff',
    borderRadius: 2,
    scaleY: 0.8,
    transition: {
      ease: ease,
      duration: 0.05
    }
  },
  carouselNext: {
    ...carousel
  },
  carouselPrev: {
    ...carousel
  },
  carouselNextDown: {
    ...carousel,
    scale: 0.88
  },
  carouselPrevDown: {
    ...carousel,
    scale: 0.88
  }
}

export default variants
