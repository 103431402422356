import * as React from 'react'
import Link from 'next/link'

// Components
import RawButton from '../RawButton'

/**
 * A link that looks like a button.
 */
const ButtonLink: React.FC<LinkProps> = ({
  children,
  href = '',
  ...props
}) => {
  if (!(href.startsWith('http://') || href.startsWith('https://'))) {
    return (
      <Link href={href} passHref scroll={false}>
        <RawButton
          {...props}
          as="a"
        >
          {children}
        </RawButton>
      </Link>
    )
  }

  return (
    <RawButton
      {...props}
      as="a"
      href={href === '' ? undefined : href}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children}
    </RawButton>
  )
}

export default ButtonLink
