import type { RefObject } from 'react'
import { useEffect, useState } from 'react'

/**
 * Method to get the offset from the right side of the screen to the element
 * @param isHookDisabled Disable the hook from updating which results in a minor performance improvement when the element is not changing
 */
export default function useRightOffset(element: RefObject<HTMLElement | null>, isHookDisabled: boolean) {
  const [rightOffset, setRightOffset] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      if (isHookDisabled || !element.current) {
        return
      }
      const { right } = element.current.getBoundingClientRect()
      const screenWidth = window.innerWidth
      const offset = screenWidth - right
      setRightOffset(offset)
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [element, isHookDisabled])

  return rightOffset
}

