import type { FooterProps } from '.'

export const footerData: FooterProps = {
  sitemap: [
    {
      title: 'Projects',
      href: '/projects'
    },
    {
      title: 'Services',
      href: '/services',
      items: [
        {
          title: 'Branding',
          href: '/services/branding'
        },
        {
          title: 'UX Design',
          href: '/services/ux-design'
        },
        {
          title: 'Visual Design',
          href: '/services/visual-design'
        },
        {
          title: 'Development',
          href: '/services/development'
        }
      ]
    },
    {
      title: 'About',
      href: '/about',
      items: [
        {
          title: 'Culture',
          href: '/about/culture'
        },
        {
          title: 'Careers',
          href: '/about/careers'
        }
      ]
    },
    {
      title: 'Blog',
      href: '/blog'
    },
    {
      title: 'Contact',
      href: '/contact'
    }
  ],
  channels: [
    {
      title: 'Twitter',
      href: 'https://twitter.com/yummygum'
    },
    {
      title: 'Instagram',
      href: 'https://www.instagram.com/yummygum/'
    },
    {
      title: 'Linkedin',
      href: 'https://www.linkedin.com/company/yummygum'
    }
  ]
}
