/* eslint-disable max-statements */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo
} from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import {
  AnimatePresence,
  m,
  LazyMotion,
  domAnimation
} from 'framer-motion'

// Hooks
import {
  useOutsideClick,
  useLockBodyScroll,
  useKeyUp,
  useMediaQuery,
  useRightOffset
} from '@/hooks'

// Lib
import { getAllActiveCareers, getBanner } from '@/lib/api'

// Components
import Icon from '@/components/atoms/Icon'
import Tooltip from '@/components/atoms/Tooltip'
import Button from '@/components/atoms/Button'
import Grid, { Col } from '@/components/meta/Grid'
import ButtonLink from '@/components/atoms/ButtonLink'
import AnimatedMark from '@/components/atoms/AnimatedMark'
import Banner from '@/components/molecules/Banner'

// Constants
import { ease, durationRegular } from '@/constants/animations'

// Types
import type { iconComponents } from '@/components/atoms/Icon'

export interface navDataTypes {
  mainItems: {
    label: string,
    href: string
  }[]
  subItems: {
    label: string,
    href: string
  }[]
}

interface Socials {
  items: {
    label: string,
    href: string
    icon?: keyof typeof iconComponents
  }[]
}

// Context
import AppContext from '@/lib/context'
import { usePosition } from '@/lib/fixedMarkContext'

// Styling
import styles from './MainNav.module.scss'

const navData = {
  mainItems: [
    {
      label: 'Projects',
      href: '/projects'
    },
    {
      label: 'Services',
      href: '/services'
    },
    {
      label: 'About',
      href: '/about'
    },
    {
      label: 'Contact',
      href: '/contact'
    }
  ],
  subItems: [
    {
      label: 'Culture',
      href: '/about/culture'
    },
    {
      label: 'Careers',
      href: '/about/careers'
    },
    {
      label: 'Our Blog',
      href: '/blog'
    }
  ]
} as navDataTypes

const socials = {
  items: [
    {
      label: 'Twitter',
      href: 'https://twitter.com/yummygum',
      icon: 'twitter'
    },
    {
      label: 'Instagram',
      href: 'https://www.instagram.com/yummygum/',
      icon: 'instagram'
    },
    {
      label: 'LinkedIn',
      href: 'https://www.linkedin.com/company/yummygum/',
      icon: 'linkedin'
    },
    {
      label: 'Dribbble',
      href: 'https://dribbble.com/yummygum',
      icon: 'dribbble'
    },
    {
      label: 'GitHub',
      href: 'https://github.com/yummygum',
      icon: 'github'
    },
    {
      label: 'NPM',
      href: 'https://www.npmjs.com/~yummygum',
      icon: 'npm'
    }
  ]
} as Socials

export interface NavProps {
  isDark?: boolean
}

interface INavBannerProps {
  title?: string | null
  description?: string | null
  isShown: boolean | null
}

/*
 * TODO: Leave this here for later inspection.
 * const getItemIndex = (idx: number) => Math.abs(navData.mainItems.length - idx - 1)
 */
const getItemIndex = (idx: number) => idx

const transitionOptions = (delay = 0) => {
  const options = {
    duration: durationRegular,
    ease: ease,
    delay
  }

  return options
}

// Animation values
const initial = {
  opacity: 0,
  translateY: 60
}

const initialSub = {
  opacity: 0,
  translateY: 30
}

const expanded = {
  opacity: 1,
  translateY: 0
}

const MainNav: React.FC<NavProps> = ({ isDark }) => {
  const isMobile = useMediaQuery('(max-width: 562px)')
  const isNotDesktop1Xl = useMediaQuery('(max-width: 1649px)')
  const router = useRouter()
  const [scrollY, setScrollY] = useState<number>(0)
  const logoRef = useRef<HTMLImageElement | null>(null)
  const { setPosition } = usePosition()

  const navRef = useRef(null)
  const menuButtonRef = useRef(null)
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)
  const [isButtonHover, setIsButtonHovering] = useState(false)

  const [careers, setCareers] = useState<number>(0)
  const [bannerData, setBannerData] = useState<INavBannerProps | null>(null)
  const rightOffset = useRightOffset(menuButtonRef, isNotDesktop1Xl)

  useEffect(() => {
    async function getActiveCareers() {
      const jobOpenings = await getAllActiveCareers()
      setCareers(jobOpenings.openings.length)
    }

    async function getBannerData() {
      const { banner } = await getBanner()

      setBannerData({
        title: banner?.title,
        description: banner?.description,
        isShown: banner?.isShown
      })
    }

    getActiveCareers()
    getBannerData()
  }, [])

  const handleClose = () => {
    setIsMenuExpanded(false)
    setIsButtonHovering(false)
  }

  useLockBodyScroll(isMenuExpanded)
  useKeyUp('Escape', () => handleClose())
  useOutsideClick(navRef, () => handleClose())

  useEffect(() => {
    // Hide nav when switching routes.
    router.events.on('routeChangeStart', handleClose)
  }, [router.events])

  /** We have a minor use case, our use case doesn't require the intensive code that framer-motion runs */
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const offset = isMobile ? 140 : 100
      const start = isMobile ? 40 : 0

      const handleScroll = () => {
        if (isMobile) {
          if (window.scrollY > offset) {
            setScrollY(100)
          } else if (window.scrollY < start) {
            setScrollY(0)
          } else if (window.scrollY > start && window.scrollY < offset) {
            setScrollY(window.scrollY - start)
          }
        } else if (window.scrollY > 100) {
          setScrollY(100)
        } else {
          setScrollY(window.scrollY)
        }
      }

      window.addEventListener('scroll', handleScroll, { passive: true })

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [isMobile])

  const { logoColor } = useContext(AppContext)

  const logoSrc = useMemo(() => {
    if (logoColor) {
      return `/img/logo/word-${logoColor === 'fresh-mint' ? 'black': 'white'}.svg`
    }

    return '/img/logo/word-black.svg'
  }, [logoColor])

  const updatePosition = () => {
    if (logoRef.current) {
      const { x } = logoRef.current.getBoundingClientRect()
      setPosition({ x })
    }
  }

  useEffect(() => {
    updatePosition()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollY])

  useEffect(() => {
    updatePosition()

    window.addEventListener('resize', updatePosition)

    return () => {
      window.removeEventListener('resize', updatePosition)
    }
  }, [])

  return (
    <>
      <nav
        className={`
          ${styles['main-nav']}
          ${isMenuExpanded ? styles['is-expanded'] : ''}
        `}
        ref={navRef}
      >
        <div className={styles['top-bar']}>
          <Grid size="l">
            <Col className={styles['bar-container']} span={12}>
              <LazyMotion features={domAnimation} strict>
                <m.div
                  style={{
                    y: -scrollY
                  }}
                >
                  <Link href="/" prefetch={false} scroll={false}>
                    <a className={`${styles.logo}`}>
                      <img
                        alt=""
                        height={40}
                        ref={logoRef}
                        src="/img/logo/mark-fresh-mint.svg"
                        style={{
                          opacity: 0
                        }}
                      />

                      <img alt="Yummygum" height={29} src={logoSrc} width={163} />
                    </a>
                  </Link>
                </m.div>

                <m.div className={styles.cta}>
                  <AnimatePresence>
                    {isMenuExpanded && (
                      <LazyMotion features={domAnimation} strict>
                        <m.div
                          animate={{ opacity: 1 }}
                          className={styles.overlay}
                          exit={{ opacity: 0 }}
                          initial={{ opacity: 0 }}
                          transition={transitionOptions(0.5)}
                        />
                      </LazyMotion>
                    )}
                  </AnimatePresence>

                  {/* Main menu content */}
                  <div className={styles.menu}>
                    <div
                      className={styles['menu-container']}
                      style={{
                        '--offset-right': `${rightOffset}px`,
                        '--offset-top': bannerData?.isShown ? '256px' : '180px'
                      } as React.CSSProperties}
                    >
                      <AnimatePresence>
                        {(isMenuExpanded && bannerData?.isShown) && (
                          <m.div
                            animate={{
                              opacity: 1,
                              y: 0
                            }}
                            className={styles.banner}
                            exit={{
                              opacity: 0,
                              y: 0
                            }}
                            initial={{
                              opacity: 0,
                              y: 0
                            }}
                            transition={
                              transitionOptions(0.8 + 5 * 0.067)
                            }
                          >
                            <Banner
                              description={bannerData.description}
                              isHovering={isButtonHover}
                              start={isMenuExpanded}
                              title={bannerData.title}
                            />
                          </m.div>
                        )}
                      </AnimatePresence>

                      <Link href="/" scroll={false}>
                        <a className={styles.mark}>
                          <AnimatedMark
                            isEntering={isMenuExpanded}
                            isExiting={false}
                          />
                        </a>
                      </Link>

                      <ButtonLink
                        className={styles['start-button-in-menu']}
                        href="/contact/#fill-in-form"
                        isInversed={isDark && !isMenuExpanded}
                        onMouseOver={() => setIsButtonHovering(true)}
                        variation="primary"
                      >
                        Start a project
                      </ButtonLink>

                      <div className={`${styles.group} ${styles.small}`}>
                        <ul className={styles['sub-items']}>
                          <AnimatePresence>
                            {isMenuExpanded && (
                              navData.subItems.map((item, idx) => (
                                <LazyMotion features={domAnimation} key={idx} strict>
                                  <li>
                                    <m.div
                                      animate={expanded}
                                      exit={initialSub}
                                      initial={initialSub}
                                      transition={
                                        transitionOptions(0.8 + idx * 0.067)
                                      }
                                    >
                                      <Link href={item.href} prefetch={false} scroll={false}>
                                        {item.label === 'Careers' && careers !== 0
                                          ? (
                                            <a className={styles['career-container']}>
                                              <span className={`${styles.career} underline body-l`}>
                                                {item.label}
                                              </span>
                                              <span className={`${styles['career-count']} heading-m`}>{careers}</span>
                                            </a>
                                          )
                                          : (
                                            <a className="body-l underline">
                                              <span>
                                                {item.label}
                                              </span>
                                            </a>
                                          )}
                                      </Link>
                                    </m.div>
                                  </li>
                                </LazyMotion>
                              ))
                            )}
                          </AnimatePresence>
                        </ul>
                      </div>

                      <div className={`${styles.group} ${styles.main}`}>
                        <ul className={styles['main-items']}>
                          <AnimatePresence>
                            {isMenuExpanded && (
                              navData.mainItems.map((item, idx) => (
                                <LazyMotion features={domAnimation} key={idx} strict>
                                  <li>
                                    <Link href={item.href} prefetch={false} scroll={false}>
                                      <m.a
                                        animate={expanded}
                                        className="heading-3-xl underline"
                                        exit={initial}
                                        initial={initial}
                                        transition={
                                          transitionOptions(0.6 + idx * 0.067)
                                        }
                                      >
                                        <span>
                                          {item.label}
                                        </span>
                                      </m.a>
                                    </Link>
                                  </li>
                                </LazyMotion>
                              ))
                            )}
                          </AnimatePresence>
                        </ul>
                      </div>

                      <div className={styles.footer}>
                        <ButtonLink
                          className={styles.contact}
                          href="/contact"
                          isFullWidth
                          variation="primary"
                        >
                          Let&apos;s start a project
                        </ButtonLink>

                        <ul className={styles.socials}>
                          {
                            socials.items.map((item, resourcesIdx) => typeof item.icon !== 'undefined' && (
                              <li
                                key={resourcesIdx}
                                style={{ '--index': getItemIndex(resourcesIdx) } as React.CSSProperties}
                              >
                                <Tooltip
                                  id={item.icon}
                                  label={item.label}
                                >
                                  <a
                                    href={item.href}
                                    rel="noreferrer"
                                    target={item.href.includes('http') ? '_blank' : ''}
                                  >
                                    {item.label}
                                    <Icon name={item.icon} />
                                  </a>
                                </Tooltip>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  </div>

                  <ButtonLink
                    className={styles['start-button']}
                    href="/contact/#fill-in-form"
                    isInversed={isDark && !isMenuExpanded}
                    variation="primary"
                  >
                    Start a project
                  </ButtonLink>

                  <Button
                    className={styles['menu-button']}
                    hasBlowingBubble
                    hasNegativeHover
                    icon={isMenuExpanded ? 'close' : 'menu'}
                    isInversed={isDark && !isMenuExpanded}
                    onClick={() => {
                      setIsMenuExpanded(!isMenuExpanded)

                      // Handle close func when menu is expanded because of side effects that depends on the handleClose
                      isMenuExpanded && handleClose()
                    }}
                    ref={menuButtonRef}
                    variation="secondary"
                  >
                    {isMenuExpanded ? 'Close' : 'Menu'}
                  </Button>
                </m.div>
              </LazyMotion>
            </Col>
          </Grid>
        </div>
      </nav>
    </>
  )
}

export default MainNav
