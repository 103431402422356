import React, { useEffect } from 'react'
import { durationFast } from '@/constants/animations'
import { IconSize } from '@/lib/enums'
import {
  AnimatePresence,
  LazyMotion,
  domAnimation,
  m
} from 'framer-motion'

// Styling
import styles from './SignupInput.module.scss'

// Component
import Icon from '../Icon'
import Button from '../Button'

// Types
import type { InputProps } from '../Input'

export type SignupInputProps = InputProps

const SignupInput: React.FC<SignupInputProps> = ({
  id,
  label,
  size = 'm',
  placeholder = ' ',
  isDisabled,
  hasError = false,
  error,
  hasSuccess = false,
  success,
  type = 'text',
  isOptional,
  onFocus,
  autoComplete = 'on',
  spellCheck = true,
  rules,
  register,
  name,
  className = '',
  ...props
}) => {
  const [buttonIsDisabled, setButtonIsDisabled] = React.useState(true)
  const [isFilledIn, setIsFilledIn] = React.useState(false)

  useEffect(() => {
    if (hasError){
      setButtonIsDisabled(hasError)
    }
  }, [hasError])

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (hasError) {
      return
    }

    setIsFilledIn(event.target.value.length > 0)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setButtonIsDisabled(event.target.value.length === 0)
    props.onChange?.(event)
  }

  return (
    <div className={`
      ${styles['signup-input']}
      ${styles[size]}
      ${className}
    `}
    >
      {label && (
        <label
          className={`
            heading-m
            ${styles.label}
            ${isDisabled ? styles['is-disabled'] : ''}
            ${isFilledIn ? styles['is-filled'] : ''}
          `}
          htmlFor={id}
        >
          {label}
        </label>
      )}

      <div
        className={`${styles['signup-input-container']}`}
      >
        <input
          {...props}
          autoComplete={autoComplete}
          className={`
            ${styles['signup-input-element']}
            ${styles[size]}
            ${hasError ? styles['has-error'] : ''}
            ${isFilledIn ? styles['is-filled'] : ''}
          `}
          disabled={isDisabled}
          id={id}
          onFocus={onFocus}
          placeholder={placeholder}
          spellCheck={spellCheck}
          type={type}
          {...register(name, {
            ...rules,
            required: !isOptional,
            onChange: handleChange,
            onBlur: handleBlur
          })}
        />

        <Button
          className={`${styles['signup-button']}`}
          icon="arrow"
          isDisabled={buttonIsDisabled}
          isInversed
          size={size}
          type="submit"
        />
      </div>

      <AnimatePresence>
        {(hasError && error) && (
          <label
            className="heading-m"
            htmlFor={id}
          >
            <LazyMotion features={domAnimation}>
              <m.div
                animate={{
                  opacity: 1,
                  y: 0
                }}
                className={styles.error}
                exit={{
                  opacity: 0,
                  y: -40
                }}
                initial={{
                  opacity: 0,
                  y: -40
                }}
                transition={{ duration: durationFast }}
              >
                <Icon name="error" size={IconSize.XLarge}/>
                <span className="body-s">{error}</span>
              </m.div>
            </LazyMotion>
          </label>
        )}

        {(!hasError && hasSuccess && success) && (
          <label
            className="heading-m"
            htmlFor={id}
          >
            <LazyMotion features={domAnimation}>
              <m.div
                animate={{
                  opacity: 1,
                  y: 0
                }}
                className={styles.success}
                exit={{
                  opacity: 0,
                  y: -40
                }}
                initial={{
                  opacity: 0,
                  y: -40
                }}
                transition={{ duration: durationFast }}
              >
                <Icon name="success" size={IconSize.XLarge}/>
                <span className="body-s">{success}</span>
              </m.div>
            </LazyMotion>
          </label>
        )}
      </AnimatePresence>
    </div>
  )
}

export default SignupInput
