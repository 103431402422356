import {
  responsiveImageFragment,
  projectFragment
} from '@/lib/api/fragments'
import { gql } from '@/lib/api/gql'

export const FILTER_PROJECTS_BY_CATEGORIES_QUERY = gql`
  query FilterProjectsByCategoriesQuery($categories: [ItemId]) {
    _allProjectsMeta(filter: {categories: {allIn: $categories}}) {
      count
    }
    projects: allProjects(
      orderBy: position_ASC,
      filter: {categories: {allIn: $categories}},
      first: 7
    ) {
      ...projectFragment
    }
  }

  ${responsiveImageFragment}
  ${projectFragment}
`
