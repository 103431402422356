import React from 'react'

// Hooks
import { useBubble } from '@/hooks'
import Icon from '../Icon'

// Types
import { IconRotate } from '@/lib/enums'

// Styles
import styles from './Button.module.scss'

type Props = ILinkProps | IButtonProps

/**
 * This is the Raw button component, it implements the Button component without implementing specific elements.
 *
 * ! This element should never be directly used, it serves as a Base for Link and Button
 */
const RawButton = React.forwardRef<HTMLSpanElement, Props>(({
  as: Component,
  children,
  variation = 'primary',
  size = 'm',
  icon,
  iconPosition = 'left',
  iconDirection = IconRotate.East,
  isFullWidth,
  isDisabled,
  isInversed,
  className = '',
  contentClassName = '',
  hasBlowingBubble,
  hasNegativeHover,
  ...props
}, ref) => {
  const isTertiary = variation === 'tertiary'
  const inversedBubble = isInversed || variation === 'primary' ? 'var(--white-000)' : 'var(--sweet-liquorice-900)'
  const [bubbleRef, wrapperRef] = useBubble({
    isDisabled: isTertiary || isDisabled,
    color: hasBlowingBubble || hasNegativeHover ? inversedBubble : 'var(--fresh-mint-500)'
  })

  // Shared classNames (less duplicate code)
  const sharedClassNames = `
    ${styles[size]} ${styles[variation]} ${isFullWidth && children ? styles['full-width'] : ''} ${isDisabled ? styles.disabled : ''} ${children ? '' : styles['icon-only']} ${isTertiary ? styles['is-tertiary'] : ''} ${isInversed ? styles.inversed : ''} ${hasBlowingBubble ? styles.bubble : ''} ${hasNegativeHover ? styles.negative : ''}
  `

  return (
    <Component
      {...props}
      aria-disabled={isDisabled ? true : null}
      className={`
        ${styles.button}
        ${className}
        ${sharedClassNames}
      `}
      disabled={isDisabled ? true : null}
      ref={wrapperRef}
    >
      <span ref={ref}>
        <span
          className={`
          ${styles['button-content']}
          ${sharedClassNames}
          ${contentClassName}
        `}
          ref={bubbleRef}
        >
          {(icon && iconPosition === 'left') && (
            <span className={styles.icon}>
              <Icon direction={iconDirection} name={icon} />
            </span>
          )}

          {children && (
            <span className={styles['button-children']}>{children}</span>
          )}

          {(icon && iconPosition === 'right') && (
            <span className={styles.icon}>
              <Icon direction={iconDirection} name={icon} />
            </span>
          )}
        </span>
      </span>
    </Component>
  )
})

RawButton.displayName = 'RawButton'

export default RawButton
