import { baseImageFragment } from '@/lib/api/fragments'
import { gql } from '@/lib/api/gql'

export const projectFragment = gql`
  fragment projectFragment on ProjectRecord {
    title: companyName
    slug
    date
    categories {
      category
    }
    companyName
    companyUrl
    cardDescription
    thumbnail {
      ...baseImageFragment
    }
    thumbnailSize
    thumbnailColor {
      hex
    }
  }

  ${baseImageFragment}
`
