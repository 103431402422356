import React, { useRef, useState } from 'react'

// Hooks
import { useKeyUp } from '@/hooks'

// Styling
import styles from './Tooltip.module.scss'

// Types
export interface TooltipProps {
  id: string
  label: string
  className?: string
}

const Tooltip: React.FC<TooltipProps> = ({
  id,
  label,
  className = '',
  children
}) => {
  const [close, setClose] = useState(false)
  const ref = useRef(null)

  // Close on escape
  useKeyUp('Escape', () => setClose(true))

  return (
    <div
      className={styles.container}
      onKeyDown={() => setClose(true)}
      onKeyUp={() => setClose(false)}
      onMouseEnter={() => setClose(false)}
      onMouseLeave={() => setClose(true)}
    >
      <div
        aria-describedby={id}
        className={`${styles.tooltip} ${className} ${close ? styles.close : ''}`}
        ref={ref}
      >
        <p className="body-s">{label}</p>
      </div>

      {children}
    </div>
  )
}

export default Tooltip
