import React, {
  createContext,
  useContext,
  useState
} from 'react'

interface Position {
  x: number;
}

interface PositionContextType {
  position: Position;
  setPosition: React.Dispatch<React.SetStateAction<Position>>;
}

const PositionContext = createContext<PositionContextType | null>(null)

const usePosition = (): PositionContextType => {
  const context = useContext(PositionContext)
  if (!context) {
    throw new Error('usePosition must be used within a PositionProvider')
  }
  return context
}

const PositionProvider: React.FC = ({ children }) => {
  const [position, setPosition] = useState<Position>({
    x: 0
  })

  return (
    <PositionContext.Provider value={{
      position,
      setPosition
    }}
    >
      {children}
    </PositionContext.Provider>
  )
}

export {
  PositionProvider,
  usePosition
}
