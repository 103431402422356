// Animation curves
export const ease = [
  .79,
  .14,
  .15,
  .86
]
export const easeOut = [
  .08,
  .82,
  .17,
  1
]
export const easeIn = [
  .6,
  .04,
  .98,
  .34
]
export const bouncy = [
  0.34,
  1.56,
  0.64,
  1
]

// Animation durations
export const durationXFast = 0.1
export const durationFast = 0.2
export const durationRegular = 0.4
export const durationSlow = 0.6
export const durationXSlow = 1
