import {
  useRef,
  useEffect
} from 'react'

// Styles
import styles from './bubble.module.scss'

// Types
import type {
  MutableRefObject,
  MouseEvent
} from 'react'

type Bubble = 'enter' | 'exit'
interface IBubbleArgs {
  /** Additional CSS class for Bubble */
  bubbleClassName?: string
  /** Duration of the bubble in MS (Default 400ms) */
  duration?: number
  /** Is the bubble disabled */
  isDisabled?: boolean
  /** The color of the bubble */
  color?: string
  /** The color of the bubble at the end of the animation */
  endColor?: string
  /** The scale to scale to (defaults to 2) */
  scaleTo?: number
  /** Should the bubble be deleted after the animation finishes? @default true */
  shouldDeleteAfterAnimation?: boolean
  /** How long should the exit animation take @default duration value */
  exitDuration?: number
}

interface IElement extends HTMLElement {
  disabled?: boolean
  ariaDisabled: string
}

/**
 * The first return is bubbleRef, this is the element the bubble is rendered in.
 * The second return is the wrapperRef, this is the element that hover triggers a bubble.
 */
function useBubble<ElementType extends HTMLElement>({
  bubbleClassName,
  duration,
  isDisabled = false,
  color = 'var(--fresh-mint-500)',
  endColor,
  scaleTo = 2,
  shouldDeleteAfterAnimation = true,
  exitDuration = duration
}: IBubbleArgs = {}): [
    MutableRefObject<ElementType|null>,
    MutableRefObject<ElementType|null>
  ] {
  const bubbleRef = useRef<ElementType>(null)
  const wrapperRef = useRef<ElementType>(null)

  // eslint-disable-next-line max-statements
  function setStyles(
    el: HTMLSpanElement,
    diameter: number,
    left: number,
    top: number,
    radius: number,
    event: React.MouseEvent<ElementType>
  ) {
    const { scrollX, scrollY } = window

    el.style.width = `${diameter}px`
    el.style.height = `${diameter}px`
    el.style.left = `${event.pageX -scrollX - (left + radius)}px`
    el.style.top = `${event.pageY - scrollY - (top + radius)}px`

    el.classList.add(styles.bubble)
    el.style.setProperty('--bubble-bg-color', color)
    el.style.setProperty('--bubble-bg-end-color', `${endColor ? endColor : color}`)
    el.style.setProperty('--bubble-scale-to', `${scaleTo}`)

    if (duration) {
      el.style.setProperty('--bubble-duration', `${duration}ms`)
    }

    if (exitDuration) {
      el.style.setProperty('--bubble-exit-duration', `${exitDuration}ms`)
    }

    if (bubbleClassName) {
      el.classList.add(bubbleClassName)
    }
  }

  // eslint-disable-next-line max-statements
  function createBubble(event: React.MouseEvent<ElementType>, type: Bubble) {
    // This is to get Typescript to stop complaining about disabled
    const wrapperCurrent = wrapperRef.current as IElement | null
    const bubbleCurrent = bubbleRef.current as IElement | null

    // Don't apply bubble animation on the following states:
    if (
      isDisabled
      || wrapperCurrent?.disabled
      || wrapperCurrent?.ariaDisabled
      || bubbleCurrent?.disabled
      || bubbleCurrent?.ariaDisabled
    ){
      return
    }

    // Capture the button reference
    const container = event.currentTarget

    // Get the button's bounding box
    const {
      width,
      height,
      left,
      top
    } = container.getBoundingClientRect()

    // Remove a leftover bubble
    const bubbles = container.querySelectorAll('[data-bubble="true"]')
    for (const bubble of bubbles) {
      bubble.remove()
    }

    // Create the bubble
    const circle = document.createElement('span')
    const diameter = Math.max(width, height)
    const radius = diameter / 2
    circle.dataset.bubble = 'true'

    setStyles(
      circle,
      diameter,
      left,
      top,
      radius,
      event
    )

    if (type === 'exit') {
      circle.classList.add(styles['bubble-exit'])
    }

    bubbleRef.current?.insertBefore(circle, bubbleRef.current.childNodes[0])

    if (shouldDeleteAfterAnimation) {
      // Remove the circle after the animation is done.
      circle.addEventListener('animationend', () => {
        circle.remove()
      })
    }
  }

  useEffect(() => {
    const bubble = wrapperRef.current
    const enterAnimation = (ev: MouseEvent<ElementType>) => createBubble(ev, 'enter')
    const exitAnimation = (ev: MouseEvent<ElementType>) => createBubble(ev, 'exit')
    if (bubble) {
      // @ts-expect-error This has to do with the element typing, can be overwritten so it's fine
      bubble.addEventListener('mouseenter', enterAnimation)
      // @ts-expect-error This has to do with the element typing, can be overwritten so it's fine
      bubble.addEventListener('mouseleave', exitAnimation)
    }

    return () => {
      if (bubble) {
        // @ts-expect-error This has to do with the element typing, can be overwritten so it's fine
        bubble.removeEventListener('mouseenter', enterAnimation)
        // @ts-expect-error This has to do with the element typing, can be overwritten so it's fine
        bubble.removeEventListener('mouseleave', exitAnimation)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bubbleRef.current,
    isDisabled,
    duration,
    color,
    scaleTo
  ])

  return [bubbleRef, wrapperRef]
}

export default useBubble
