import React from 'react'

interface Props {
  span?: number,
  span1Xl?: number,
  start?: number,
  end?: number,
  alignSelf?: AlignSelf,
  children: React.ReactNode,
  className?: string,
  as?: React.ElementType
}

const Col = React.forwardRef<HTMLDivElement, Props>((
  {
    span = 12,
    span1Xl,
    start,
    end,
    alignSelf,
    children,
    className = '',
    as = 'div',
    ...props
  },
  ref
) => {
  const properties = {
    'data-col': true,
    ...(span ? { 'data-span': span } : {}),
    ...(end ? { 'data-end': true } : {}),
    style: {
      ...(span ? { '--span': span } : {}),
      ...(span1Xl ? { '--span-1-xl': span1Xl } : {}),
      ...(start ? { '--start': start } : {}),
      ...(end ? { '--end': end } : {}),
      ...(alignSelf ? { alignSelf: alignSelf } : {})
    } as React.CSSProperties
  }

  const Component = as

  return (
    <Component
      ref={ref}
      {...properties}
      className={className}
      {...props}
    >
      {children}
    </Component>
  )
})

Col.displayName = 'Col'

export default Col
export { Col }
