import { useState, useEffect } from 'react'

// Hooks
import useDebounce from './useDebounce'

function getSize() {
  return {
    width: typeof window !== 'undefined' ? window.innerWidth : undefined,
    height: typeof window !== 'undefined' ? window.innerHeight : undefined
  }
}

interface Size {
  width: number | undefined
  height: number | undefined
}

function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState(getSize)
  const debouncedValue = useDebounce(windowSize, 500)

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize())
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [debouncedValue])

  return windowSize
}

export default useWindowSize
