import { GraphQLClient } from 'graphql-request'

// Queries
import { ALL_BLOGS_SLUG_QUERY } from '@/lib/api/queries/blogs/allBlogsSlugQuery'
import { BLOG_BY_DATE } from '@/lib/api/queries/blogs/blogByDate'
import { BLOG_BY_SLUG } from '@/lib/api/queries/blogs/blogBySlug'
import { BLOGS_QUERY } from '@/lib/api/queries/blogs/blogsQuery'
import { FILTER_BLOGS_BY_CATEGORIES_PAGINATED_QUERY } from '@/lib/api/queries/blogs/filterBlogsByCategoriesPaginedQuery'
import { FILTER_BLOGS_BY_CATEGORIES_QUERY } from '@/lib/api/queries/blogs/filterBlogsByCategoriesQuery'
import { PAGINATED_BLOGS_QUERY } from '@/lib/api/queries/blogs/paginatedBlogsQuery'

import { ALL_PROJECTS_SLUG_QUERY } from '@/lib/api/queries/projects/allProjectsSlugQuery'
import { FILTER_PROJECTS_BY_CATEGORIES_PAGINATED_QUERY } from '@/lib/api/queries/projects/filterProjectsByCategoriesPaginatedQuery'
import { FILTER_PROJECTS_BY_CATEGORIES_QUERY } from '@/lib/api/queries/projects/filterProjectsByCategoriesQuery'
import { PAGINATED_PROJECT_QUERY } from '@/lib/api/queries/projects/paginatedProjectQuery'
import { PROJECT_BY_SLUG } from '@/lib/api/queries/projects/projectBySlug'
import { PROJECTS_QUERY } from '@/lib/api/queries/projects/projectsQuery'

import { ALL_EMPLOYEES_QUERY } from '@/lib/api/queries/employees/allEmployeesQuery'
import { EMPLOYEES_BY_NAME } from '@/lib/api/queries/employees/employeesByName'
import { EMPLOYEES_BY_ROLE } from '@/lib/api/queries/employees/employeesByRole'

import { ALL_ACTIVE_CAREERS_QUERY } from '@/lib/api/queries/careers/allActiveCareersQuery'
import { ALL_CAREERS_QUERY } from '@/lib/api/queries/careers/allCareersQuery'
import { CAREERS_BY_SLUG } from '@/lib/api/queries/careers/careersBySlug'
import { CAREERS_QUERY } from '@/lib/api/queries/careers/careersQuery'

import { PILLAR_BY_SLUG } from '@/lib/api/queries/pillars/pillarBySlug'
import { PILLARS_QUERY } from '@/lib/api/queries/pillars/pillarsQuery'

import { LEGAL_BY_SLUG_QUERY } from '@/lib/api/queries/legals/legalBySlugQuery'
import { LEGALS_QUERY } from '@/lib/api/queries/legals/legalsQuery'

import { SERVICE_BY_SLUG } from '@/lib/api/queries/services/serviceBySlug'
import { SERVICES_QUERY } from '@/lib/api/queries/services/servicesQuery'
import { SERVICES_ID_QUERY } from './queries/services/serviceIdBySlug'

import { ABOUT_QUERY } from '@/lib/api/queries/aboutQuery'
import { CLIENT_LOGOS_QUERY } from '@/lib/api/queries/clientLogosQuery'
import { CONTACT_QUERY } from '@/lib/api/queries/contactQuery'
import { CULTURE_QUERY } from '@/lib/api/queries/cultureQuery'
import { ERROR_PAGE_BY_CODE_QUERY } from '@/lib/api/queries/errorPageByCodeQuery'
import { HOME_QUERY } from '@/lib/api/queries/homeQuery'

import { SERVICE_PILLARS_QUERY } from './queries/services/pillar/servicePillarsQuery'
import { SERVICES_PILLAR_QUERY } from './queries/services/pillar/servicePillarBySlug'

import { BANNER_QUERY } from '@/lib/api/queries/bannerQuery'

// Types
import type { AboutQuery } from '@/lib/api/queries/__generated__/AboutQuery'
import type { AllCareersQuery } from '@/lib/api/queries/careers/__generated__/AllCareersQuery'
import type { AllActiveCareersQuery } from '@/lib/api/queries/careers/__generated__/AllActiveCareersQuery'
import type { AllEmployeesQuery } from '@/lib/api/queries/employees/__generated__/AllEmployeesQuery'
import type { BannerQuery } from '@/lib/api/queries/__generated__/BannerQuery'
import type { BlogByDate } from '@/lib/api/queries/blogs/__generated__/BlogByDate'
import type { BlogBySlug } from '@/lib/api/queries/blogs/__generated__/BlogBySlug'
import type { BlogsQuery } from '@/lib/api/queries/blogs/__generated__/BlogsQuery'
import type { CareersBySlug } from '@/lib/api/queries/careers/__generated__/CareersBySlug'
import type { CareersQuery } from '@/lib/api/queries/careers/__generated__/CareersQuery'
import type { ClientLogoQuery } from './queries/__generated__/ClientLogoQuery'
import type { ContactQuery } from '@/lib/api/queries/__generated__/ContactQuery'
import type { CultureQuery } from '@/lib/api/queries/__generated__/CultureQuery'
import type { EmployeesByName } from '@/lib/api/queries/employees/__generated__/EmployeesByName'
import type { EmployeesByRole } from '@/lib/api/queries/employees/__generated__/EmployeesByRole'
import type { FilterBlogsByCategoriesQuery } from '@/lib/api/queries/blogs/__generated__/FilterBlogsByCategoriesQuery'
import type { FilterBlogsByCategoriesPaginatedQuery } from '@/lib/api/queries/blogs/__generated__/FilterBlogsByCategoriesPaginatedQuery'
import type { FilterProjectsByCategoriesQuery } from '@/lib/api/queries/projects/__generated__/FilterProjectsByCategoriesQuery'
import type { FilterProjectsByCategoriesPaginatedQuery } from '@/lib/api/queries/projects/__generated__/FilterProjectsByCategoriesPaginatedQuery'
import type { HomeQuery } from '@/lib/api/queries/__generated__/HomeQuery'
import type { LegalBySlugQuery } from '@/lib/api/queries/legals/__generated__/LegalBySlugQuery'
import type { LegalsQuery } from '@/lib/api/queries/legals/__generated__/LegalsQuery'
import type { PaginatedBlogsQuery } from '@/lib/api/queries/blogs/__generated__/PaginatedBlogsQuery'
import type { PaginatedProjectQuery } from '@/lib/api/queries/projects/__generated__/PaginatedProjectQuery'
import type { ProjectBySlug } from '@/lib/api/queries/projects/__generated__/ProjectBySlug'
import type { ProjectsQuery } from '@/lib/api/queries/projects/__generated__/ProjectsQuery'
import type { ServiceBySlug } from '@/lib/api/queries/services/__generated__/ServiceBySlug'
import type { ServicesQuery } from '@/lib/api/queries/services/__generated__/ServicesQuery'
import type { AllProjectsSlugQuery } from '@/lib/api/queries/projects/__generated__/AllProjectsSlugQuery'
import type { AllBlogsSlugQuery } from '@/lib/api/queries/blogs/__generated__/AllBlogsSlugQuery'
import type { PillarBySlug } from '@/lib/api/queries/pillars/__generated__/PillarBySlug'
import type { PillarsQuery } from '@/lib/api/queries/pillars/__generated__/PillarsQuery'
import type { serviceIdBySlug } from './queries/services/__generated__/serviceIdBySlug'
import type { ServicesPillarBySlug } from './queries/services/pillar/__generated__/ServicesPillarBySlug'

type Slug = string | string[]
interface Config {
  variables?: unknown,
  preview?: boolean
}

// Request data helper
const request = async(query: string, { variables, preview }: Config = {}) => {
  const environment = process.env.NEXT_PUBLIC_DATOCMS_ENVIRONMENT

  const client = new GraphQLClient(
    `https://graphql.datocms.com/${ environment ? `environments/${environment}` : '' }${preview ? '/preview' : ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.NEXT_PUBLIC_DATOCMS_API_TOKEN}`
      }
    }
  )

  return client.request(query, variables)
}

// Data functions

// Home
export async function getHome(preview?: boolean): Promise<HomeQuery> {
  const data = await request(HOME_QUERY, {
    preview
  })

  return data
}

// About
export async function getAbout(preview?: boolean): Promise<AboutQuery> {
  const data = await request(ABOUT_QUERY, {
    preview
  })

  return data
}

export async function getCareers(preview?: boolean): Promise<CareersQuery> {
  const data = await request(CAREERS_QUERY, {
    preview
  })

  return data
}

export async function getCareerBySlug(slug: Slug, preview?: boolean): Promise<CareersBySlug> {
  const data = await request(CAREERS_BY_SLUG, {
    preview,
    variables: { slug }
  })

  return data
}

export async function getPillarBySlug(slug: Slug, preview?: boolean): Promise<PillarBySlug> {
  const data = await request(PILLAR_BY_SLUG, {
    preview,
    variables: { slug }
  })

  return data
}

export async function getPillars(preview?: boolean): Promise<PillarsQuery> {
  const data = await request(PILLARS_QUERY, {
    preview
  })

  return data
}

export async function getEmployeesByRole(role: string, preview?: boolean): Promise<EmployeesByRole> {
  const data = await request(EMPLOYEES_BY_ROLE, {
    preview,
    variables: { role }
  })

  return data
}

export async function getEmployeesByName(name: string, preview?: boolean): Promise<EmployeesByName> {
  const data = await request(EMPLOYEES_BY_NAME, {
    preview,
    variables: { name }
  })

  return data
}

export async function getAllCareers(preview?: boolean): Promise<AllCareersQuery> {
  const data: AllCareersQuery = await request(ALL_CAREERS_QUERY, {
    preview
  })

  return data
}

export async function getAllActiveCareers(preview?: boolean): Promise<AllActiveCareersQuery> {
  const data: AllActiveCareersQuery = await request(ALL_ACTIVE_CAREERS_QUERY, {
    preview
  })

  return data
}

export async function getAllEmployees(preview?: boolean): Promise<AllEmployeesQuery> {
  const data = await request(ALL_EMPLOYEES_QUERY, {
    preview
  })

  return data
}

export async function getCulture(preview?: boolean): Promise<CultureQuery> {
  const data = await request(CULTURE_QUERY, {
    preview
  })

  return data
}

// Blogs
export async function getBlogs(skip: number, preview?: boolean, take = 100): Promise<BlogsQuery> {
  const data = await request(BLOGS_QUERY, {
    preview,
    variables: {
      skip,
      take
    }
  })

  return data
}

export async function getBlogBySlug(slug: Slug, preview?: boolean): Promise<BlogBySlug> {
  const data = await request(BLOG_BY_SLUG, {
    preview,
    variables: { slug }
  })

  return data
}

// Contact
export async function getContact(preview?: boolean): Promise<ContactQuery> {
  const data = await request(CONTACT_QUERY, {
    preview
  })

  return data
}

// Clients
export async function getClientLogos(preview?: boolean): Promise<ClientLogoQuery> {
  const data = await request(CLIENT_LOGOS_QUERY, {
    preview
  })

  return data
}

// Legal
export async function getLegalBySlug(slug: Slug, preview?: boolean): Promise<LegalBySlugQuery> {
  const data = await request(LEGAL_BY_SLUG_QUERY, {
    preview,
    variables: { slug }
  })

  return data
}

export async function getLegals(preview?: boolean): Promise<LegalsQuery> {
  const data = await request(LEGALS_QUERY, {
    preview
  })

  return data
}

// Project
export async function getProjects(skip: number, preview?: boolean, take = 100): Promise<ProjectsQuery> {
  const data = await request(PROJECTS_QUERY, {
    preview,
    variables: {
      skip,
      take
    }

  })

  return data
}

export async function getProjectBySlug(slug: Slug, preview?: boolean): Promise<ProjectBySlug> {
  const data = await request(PROJECT_BY_SLUG, {
    preview,
    variables: { slug }
  })

  return data
}

// Services
export async function getServices(preview?: boolean): Promise<ServicesQuery> {
  const data = await request(SERVICES_QUERY, {
    preview
  })

  return data
}

export async function getServiceBySlug(slug: Slug, preview?: boolean): Promise<ServiceBySlug> {
  const data = await request(SERVICE_BY_SLUG, {
    preview,
    variables: { slug }
  })

  return data
}

export async function getServiceIdBySlug(slug: Slug, preview?: boolean): Promise<serviceIdBySlug> {
  const data = await request(SERVICES_ID_QUERY, {
    preview,
    variables: { slug }
  })
  return data
}

/* eslint-disable-next-line max-len */
export async function getServicePillarBySlug(slug: Slug, serviceId: string, preview?: boolean): Promise<ServicesPillarBySlug> {
  const data = await request(SERVICES_PILLAR_QUERY, {
    preview,
    variables: {
      slug,
      serviceId
    }
  })

  return data
}

export async function getServicePillars(preview?: boolean) {
  const data = await request(SERVICE_PILLARS_QUERY, {
    preview
  })

  return data
}

export async function getBlogPostsByDate(amount: number, preview?: boolean): Promise<BlogByDate> {
  const data = await request(BLOG_BY_DATE, {
    preview,
    variables: { amount }
  })

  return data
}

export function getMoreProjects(start: number, amount: number, preview?: boolean): Promise<PaginatedProjectQuery> {
  return request(PAGINATED_PROJECT_QUERY, {
    preview,
    variables: {
      start,
      limit: amount
    }
  })
}

export function getProjectsByCategories(
  categories: string[],
  preview?: boolean
): Promise<FilterProjectsByCategoriesQuery> {
  return request(FILTER_PROJECTS_BY_CATEGORIES_QUERY, {
    preview,
    variables: {
      categories
    }
  })
}

export function getMoreFilteredProjects(
  categories: string[],
  start: number,
  amount: number,
  preview?: boolean
): Promise<FilterProjectsByCategoriesPaginatedQuery> {
  return request(FILTER_PROJECTS_BY_CATEGORIES_PAGINATED_QUERY, {
    preview,
    variables: {
      categories,
      start,
      limit: amount
    }
  })
}

export function getMoreBlogs(start: number, amount: number, preview?: boolean): Promise<PaginatedBlogsQuery> {
  return request(PAGINATED_BLOGS_QUERY, {
    preview,
    variables: {
      start,
      limit: amount
    }
  })
}

export function getBlogsByCategories(categories: string[], preview?: boolean): Promise<FilterBlogsByCategoriesQuery> {
  return request(FILTER_BLOGS_BY_CATEGORIES_QUERY, {
    preview,
    variables: {
      categories
    }
  })
}

export function getMoreFilteredBlogs(
  categories: string[],
  start: number,
  amount: number,
  preview?: boolean
): Promise<FilterBlogsByCategoriesPaginatedQuery> {
  return request(FILTER_BLOGS_BY_CATEGORIES_PAGINATED_QUERY, {
    preview,
    variables: {
      categories,
      start,
      limit: amount
    }
  })
}

export function getAllProjectsSlug(preview?: boolean): Promise<AllProjectsSlugQuery> {
  return request(ALL_PROJECTS_SLUG_QUERY, {
    preview
  })
}

export function getAllBlogsSlug(preview?: boolean): Promise<AllBlogsSlugQuery> {
  return request(ALL_BLOGS_SLUG_QUERY, {
    preview
  })
}

// Error page
export async function getErrorPageByCode(code: string, preview?: boolean) {
  const data = await request(ERROR_PAGE_BY_CODE_QUERY, {
    preview,
    variables: { code }
  })

  return data
}

export async function getBanner(preview?: boolean): Promise<BannerQuery> {
  const data: BannerQuery = await request(BANNER_QUERY, {
    preview
  })

  return data
}
