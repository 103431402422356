import React, { useState, useEffect } from 'react'

// Utils
import { getMonthNameWeeksFromNow } from '@/utils'

// Components
import ShopIllustration from './ShopIllustration'
import Squiggle from './Squiggle'

// Styling
import styles from './Banner.module.scss'

const Banner: React.FC<IBannerProps> = ({
  className = '',
  description,
  weekCount = 4,
  title = '',
  start,
  isHovering
}) => {
  const [isAniDone, setIsAniDone] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setIsAniDone(true), 1700)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  // Replace {week} with weekCount
  const newTitle = title?.replace(/{week}/g, `${weekCount}`)
  // Replace {month} with calculated month name (e.g. January) based on weekCount
  const newDescription = description?.replace(/{month}/g, getMonthNameWeeksFromNow(weekCount))

  return (
    <div
      className={`
        ${styles.banner}
        ${className}
      `}
    >
      <div className={`${styles.content} ${isAniDone ? styles.done : ''}`}>
        <h2 className="heading-s">
          <span>{newTitle}</span>
        </h2>
        <p className="body-s">
          {newDescription}

          <Squiggle className={styles.squiggle} start={isHovering} />
        </p>
      </div>

      <ShopIllustration className={styles.shop} start={start} />
    </div>
  )
}

export default Banner
