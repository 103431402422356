/* eslint-disable max-statements */
import React, {
  useState,
  useRef,
  useEffect,
  useMemo
} from 'react'
import {
  LazyMotion,
  domAnimation,
  m,
  MotionConfig,
  useMotionValue
} from 'framer-motion'
import { useRouter } from 'next/router'

// Types
export interface CursorProps {
  name?: string;
}

type CursorEffect = 'default'
  | 'circle'
  | 'click'
  | 'line'
  | 'lineClick'
  | 'carouselNext'
  | 'carouselPrev'
  | 'carouselNextDown'
  | 'carouselPrevDown'

// Hooks
import { useWindowSize } from '@/hooks'

// Variants
import variants from './variants'

// Styling
import styles from './Cursor.module.scss'
import Icon from '../Icon'
import { IconRotate, IconSize } from '@/lib/enums'

function hasTextSelected() {
  if (typeof window.getSelection !== 'undefined') {
    const selection = window.getSelection()
    if (selection && selection.toString().length > 0) {
      return true
    }
  }
  return false
}

const Cursor: React.FC<CursorProps> = () => {
  // Refs
  const ref = useRef<HTMLDivElement>(null)

  // Variables
  const vars = variants
  const { current } = ref

  // States
  const [effect, setEffect] = useState<CursorEffect>('default')
  const [isClicking, setIsClicking] = useState(false)

  // Hooks
  const size = useWindowSize()
  const router = useRouter()

  const x = useMotionValue(0)
  const y = useMotionValue(0)

  // Handle mouse movement and add the mouse movement to the window. Also add the cursor-active class to the cursor so it will be shown when the mouse is moving
  useEffect(() => {
    const handleMouseMove = (evt: MouseEvent) => {
      current?.classList.add(styles['cursor-active'])
      x.set(evt.clientX - 12)
      y.set(evt.clientY - 12)

      if (hasTextSelected()) {
        setEffect('line')
      }
    }

    const raf = requestAnimationFrame(() => {
      window.addEventListener('mousemove', handleMouseMove)
    })

    return () => {
      cancelAnimationFrame(raf)
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [
    current?.classList,
    x,
    y
  ])

  function setMouseEnterEffect(tagName: string, dataset: DOMStringMap) {
    if (dataset.carouselNext !== undefined) {
      setEffect('carouselNext')
      return
    }

    if (dataset.carouselPrev !== undefined) {
      setEffect('carouselPrev')
      return
    }

    switch (tagName) {
      case 'INPUT':
      case 'TEXTAREA':
        setEffect('line')
        break
      case 'DIV':
        setEffect('default')
        break
      case 'A':
      case 'BUTTON':
      default:
        setEffect('circle')
    }
  }

  function setMouseDownEffect(tagName: string, dataset: DOMStringMap) {
    if (dataset.carouselNext !== undefined) {
      setEffect('carouselNextDown')
      return
    }

    if (dataset.carouselPrev !== undefined) {
      setEffect('carouselPrevDown')
      return
    }

    switch (tagName) {
      case 'INPUT':
      case 'TEXTAREA':
        setEffect('lineClick')
        break
      case 'A':
      case 'BUTTON':
      default:
        setEffect('click')
        break
    }
  }

  function setMouseUpEffect(tagName: string, dataset: DOMStringMap) {
    if (dataset.carouselNext !== undefined) {
      setEffect('carouselNext')
      return
    }

    if (dataset.carouselPrev !== undefined) {
      setEffect('carouselPrev')
      return
    }

    switch (tagName) {
      case 'INPUT':
      case 'TEXTAREA':
        setEffect('line')
        break
      case 'A':
      case 'BUTTON':
      default:
        setEffect('default')
        break
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleMouseDown(evt: any) {
    setIsClicking(true)

    setMouseDownEffect(
      (evt.target as Element | undefined)?.tagName ?? '',
      (evt.target as HTMLElement | undefined)?.dataset ?? {}
    )

    setTimeout(() => {
      setIsClicking(false)
      setMouseDownEffect(
        (evt.target as Element | undefined)?.tagName ?? '',
        (evt.target as HTMLElement | undefined)?.dataset ?? {}
      )
    }, 150)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleMouseUp(evt: any) {
    if (isClicking) {
      // Add a small timeout so if the user clicks fast, the click effect will still be shown
      return setTimeout(() => {
        setIsClicking(false)
        setMouseUpEffect(
          (evt.target as Element | undefined)?.tagName ?? '',
          (evt.target as HTMLElement | undefined)?.dataset ?? {}
        )
      }, 150)
    }

    setMouseUpEffect(
      (evt.target as Element | undefined)?.tagName ?? '',
      (evt.target as HTMLElement | undefined)?.dataset ?? {}
    )
  }

  // Get all the elements that need to have the cursor effect and add the cursor-element class to them.
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function handleMouseEnter(evt: any) {
      setMouseEnterEffect(
        (evt.target as Element | undefined)?.tagName ?? '',
        (evt.target as HTMLElement | undefined)?.dataset ?? {}
      )
    }

    function handleMouseLeave() {
      setEffect('default')
    }

    for (const element of document.querySelectorAll('a, button, input, textarea, [data-carousel-next], [data-carousel-prev]')) {
      element.classList.add('cursor-element')

      element.addEventListener('mouseenter', handleMouseEnter)
      element.addEventListener('mouseleave', handleMouseLeave)
    }
    // Cleanup function to remove the cursor-element class from all the elements that have it and remove the mouseenter and mouseleave event listeners from them
    return () => {
      for (const element of document.querySelectorAll('a, button')) {
        element.classList.remove('cursor-element')

        element.removeEventListener('mouseenter', handleMouseEnter)
        element.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [router.pathname, effect])

  /*
   * For any screen above 1024px, add event listeners to the window to detect when the user clicks on the screen.
   * When the user clicks, add the click class to the cursor.
   */
  useEffect(() => {
    if ((size.width ?? 0) > 1024) {
      // Add mousedown event listener to the window
      window.addEventListener('mousedown', handleMouseDown)

      // Add mouseup event listener to the window
      window.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      if ((size.width ?? 0) > 1024) {
        window.removeEventListener('mousedown', handleMouseDown)
        window.removeEventListener('mouseup', handleMouseUp)
      }
    }
  })

  const isCarouselCursor = useMemo(() => {
    return effect === 'carouselNext'
    || effect === 'carouselPrev'
    || effect === 'carouselNextDown'
    || effect === 'carouselPrevDown'
  }, [effect])

  return (
    <LazyMotion features={domAnimation}>
      <MotionConfig reducedMotion="user">
        <m.div
          className={styles.cursor}
          ref={ref}
          style={{
            translateX: x,
            translateY: y,
            mixBlendMode: isCarouselCursor ? 'normal' : 'difference'
          }}
        >
          <m.div
            animate={effect}
            className={`${styles['inner-cursor']}`}
            variants={vars}
          >
            {(effect === 'carouselNext' || effect === 'carouselNextDown') && (
              <Icon direction={IconRotate.East} name="arrow" size={IconSize.Large} />
            )}
            {(effect === 'carouselPrev' || effect === 'carouselPrevDown') && (
              <Icon direction={IconRotate.West} name="arrow" size={IconSize.Large} />
            )}
          </m.div>
        </m.div>
      </MotionConfig>
    </LazyMotion>

  )
}

export default Cursor
