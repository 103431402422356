import * as React from 'react'
import dynamic from 'next/dynamic'

// Using dynamic import to reduce initial bundle size
export const iconComponents = {
  ae: dynamic(() => import('./Icons/AfterEffects')),
  arrow: dynamic(() => import('./Icons/Arrow')),
  bike: dynamic(() => import('./Icons/Bike')),
  bit: dynamic(() => import('./Icons/Bit')),
  bullet: dynamic(() => import('./Icons/Bullet')),
  checkmark: dynamic(() => import('./Icons/Checkmark')),
  chevron: dynamic(() => import('./Icons/Chevron')),
  'chevron-bidirectional-vertical': dynamic(() => import('./Icons/ChevronBidirectionalVertical')),
  close: dynamic(() => import('./Icons/Close')),
  dollar: dynamic(() => import('./Icons/Dollar')),
  download: dynamic(() => import('./Icons/Download')),
  dribbble: dynamic(() => import('./Icons/Dribbble')),
  email: dynamic(() => import('./Icons/Email')),
  error: dynamic(() => import('./Icons/Error')),
  euro: dynamic(() => import('./Icons/Euro')),
  figma: dynamic(() => import('./Icons/Figma')),
  gatsby: dynamic(() => import('./Icons/Gatsby')),
  github: dynamic(() => import('./Icons/GitHub')),
  'github-tool': dynamic(() => import('./Icons/GithubTool')),
  globe: dynamic(() => import('./Icons/Globe')),
  graphql: dynamic(() => import('./Icons/Graphql')),
  'growth-budget': dynamic(() => import('./Icons/GrowthBudget')),
  gtm: dynamic(() => import('./Icons/GoogleTagManager')),
  instagram: dynamic(() => import('./Icons/Instagram')),
  jest: dynamic(() => import('./Icons/Jest')),
  linear: dynamic(() => import('./Icons/Linear')),
  linkedin: dynamic(() => import('./Icons/LinkedIn')),
  menu: dynamic(() => import('./Icons/Menu')),
  minus: dynamic(() => import('./Icons/Minus')),
  miro: dynamic(() => import('./Icons/Miro')),
  next: dynamic(() => import('./Icons/Next')),
  notion: dynamic(() => import('./Icons/Notion')),
  npm: dynamic(() => import('./Icons/Npm')),
  nuxt: dynamic(() => import('./Icons/Nuxt')),
  pause: dynamic(() => import('./Icons/Pause')),
  percentage: dynamic(() => import('./Icons/Percentage')),
  play: dynamic(() => import('./Icons/Play')),
  plus: dynamic(() => import('./Icons/Plus')),
  protopie: dynamic(() => import('./Icons/Protopie')),
  react: dynamic(() => import('./Icons/ReactIcon')),
  slack: dynamic(() => import('./Icons/Slack')),
  star: dynamic(() => import('./Icons/Star')),
  storybook: dynamic(() => import('./Icons/Storybook')),
  success: dynamic(() => import('./Icons/Success')),
  'team-retreat': dynamic(() => import('./Icons/TeamRetreat')),
  tilde: dynamic(() => import('./Icons/Tilde')),
  twitter: dynamic(() => import('./Icons/Twitter')),
  typescript: dynamic(() => import('./Icons/Typescript')),
  'vacation-time': dynamic(() => import('./Icons/VacationTime')),
  vscode: dynamic(() => import('./Icons/VSCode')),
  vue: dynamic(() => import('./Icons/Vue')),
  warning: dynamic(() => import('./Icons/Warning')),
  whimsical: dynamic(() => import('./Icons/Whimsical')),
  'yearly-bonus-raise': dynamic(() => import('./Icons/YearlyBonusRaise')),
  yoga: dynamic(() => import('./Icons/Yoga')),
  todoist: dynamic(() => import('./Icons/Todoist')),
  moneybird: dynamic(() => import('./Icons/Moneybird')),
  employes: dynamic(() => import('./Icons/Employes')),
  'google-workspace': dynamic(() => import('./Icons/GoogleWorkspace')),
  'adobe-creative-cloud': dynamic(() => import('./Icons/AdobeCreativeCloud')),
  ahrefs: dynamic(() => import('./Icons/Ahrefs')),
  'brand-introduction': dynamic(() => import('./Icons/BrandIntroduction')),
  'brand-sprint': dynamic(() => import('./Icons/BrandSprint')),
  'brand-survey': dynamic(() => import('./Icons/BrandSurvey')),
  'bundle-size': dynamic(() => import('./Icons/BundleSize')),
  'cloud-platforms': dynamic(() => import('./Icons/CloudPlatforms')),
  core: dynamic(() => import('./Icons/Core')),
  'core-and-more': dynamic(() => import('./Icons/CoreAndMore')),
  'css-over-js': dynamic(() => import('./Icons/CssOverJs')),
  'design-library': dynamic(() => import('./Icons/DesignLibrary')),
  'design-system-full-fledged': dynamic(() => import('./Icons/DesignSystemFullFledged')),
  'ecosystem-analysis': dynamic(() => import('./Icons/EcosystemAnalysis')),
  'ethnographic-field-study': dynamic(() => import('./Icons/EthnographicFieldStudy')),
  'figma-mirror': dynamic(() => import('./Icons/FigmaMirror')),
  'full-package': dynamic(() => import('./Icons/FullPackage')),
  'google-optimize': dynamic(() => import('./Icons/GoogleOptimize')),
  'guerilla-testing': dynamic(() => import('./Icons/GuerillaTesting')),
  'hot-jar': dynamic(() => import('./Icons/HotJar')),
  'lazy-loading': dynamic(() => import('./Icons/LazyLoading')),
  testing: dynamic(() => import('./Icons/Testing')),
  'local-testing': dynamic(() => import('./Icons/LocalTesting')),
  'logo-mark': dynamic(() => import('./Icons/LogoMark')),
  logotype: dynamic(() => import('./Icons/Logotype')),
  'mark-and-logotype': dynamic(() => import('./Icons/MarkAndLogotype')),
  'mobile-guidelines': dynamic(() => import('./Icons/MobileGuidelines')),
  'native-vs-web': dynamic(() => import('./Icons/NativeVsWeb')),
  possibilities: dynamic(() => import('./Icons/Possibilities')),
  'prototyping-hi-fi': dynamic(() => import('./Icons/PrototypingHiFi')),
  'prototyping-lo-fi': dynamic(() => import('./Icons/PrototypingLoFi')),
  'prototyping-static': dynamic(() => import('./Icons/PrototypingStatic')),
  'remote-unmoderated': dynamic(() => import('./Icons/RemoteUnmoderated')),
  security: dynamic(() => import('./Icons/Security')),
  'style-kit': dynamic(() => import('./Icons/StyleKit')),
  'type-of-code': dynamic(() => import('./Icons/TypeOfCode')),
  'unique-style-guide': dynamic(() => import('./Icons/UniqueStyleGuide')),
  'unstructured-interview': dynamic(() => import('./Icons/UnstructuredInterview'))
}

export type IconName = keyof typeof iconComponents;

interface Props extends ToolIconProps {
  name: IconName
}

export const iconElements = (props: React.PropsWithChildren<Props>) => {
  const { name } = props
  const Component = iconComponents[name]
  return (<Component {...props} />)
}

const Icon: React.FC<Props> = (props) => (
  <>
    {iconElements(props)}
  </>
)

export default Icon
