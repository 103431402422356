import {
  responsiveImageFragment,
  blogPostFragment
} from '@/lib/api/fragments'
import { gql } from '@/lib/api/gql'

export const FILTER_BLOGS_BY_CATEGORIES_QUERY = gql`
  query FilterBlogsByCategoriesQuery($categories: [ItemId]) {
    _allBlogPostsMeta(filter: {categories: {allIn: $categories}}) {
      count
    }
    allBlogPosts(
      orderBy: _firstPublishedAt_DESC,
      first: 12,
      filter: {categories: {allIn: $categories}}
    ) {
      ...blogPostFragment
    }
  }

  ${blogPostFragment}
  ${responsiveImageFragment}
`
