import React from 'react'

// Components
import Grid, { Col } from '@/components/meta/Grid'
import Button from '@/components/atoms/Button'
import ButtonLink from '@/components/atoms/ButtonLink'

// Types
export interface CookieNoticeProps {
  description?: string
  onAccept: () => void
  onDeny: () => void
}

// Styling
import styles from './CookieNotice.module.scss'

const CookieNotice: React.FC<CookieNoticeProps> = ({
  description = 'We use cookies so we can improve our website. Your info will not be sold or used otherwise.',
  onAccept,
  onDeny
}) => (
  <div
    aria-label="cookieconsent"
    aria-live="polite"
    className={styles['cookie-notice']}
    role="dialog"
  >
    <Grid size="l">
      <Col className={styles.container} span={12}>
        <p className="body-s">
          {description}
          <ButtonLink
            href="/legal/privacy-policy#cookies"
            isInversed
            size="s"
            variation="tertiary"
          >
            Read about our cookies
          </ButtonLink>
        </p>

        <ButtonLink
          href="/legal/privacy-policy#cookies"
          isInversed
          size="s"
          variation="tertiary"
        >
          Read about our cookies
        </ButtonLink>

        <div className={styles['button-container']}>
          <Button isInversed onClick={onDeny} size="s" variation="secondary">
          Don&lsquo;t allow
          </Button>
          <Button isInversed onClick={onAccept} size="s" variation="primary">
          Allow
          </Button>
        </div>
      </Col>
    </Grid>
  </div>
)

export default CookieNotice
