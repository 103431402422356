import React from 'react'
import { useRouter } from 'next/router'
import { AnimatePresence, motion } from 'framer-motion'
import { useNextCssRemovalPrevention } from '@madeinhaus/nextjs-page-transition'

// Hooks
import { useManagedLockBodyScroll } from '@/hooks'

// Styling
import styles from './Layout.module.scss'

// Types
import type { ReactNode } from 'react'

interface LayoutProps {
  children: ReactNode,
}

export default function TransitionLayout({ children }: LayoutProps) {
  const router = useRouter()
  const path = router.asPath
  const [lock, unlock] = useManagedLockBodyScroll()

  /*
   * Prevents the CSS from being removed on page transition
   * This relies heavily on Next JS internals, please be extremely careful
   * When upgrading Next JS versions and test this thoroughly
   */
  useNextCssRemovalPrevention()

  const transitionDuration = 0.95

  return (
    <AnimatePresence
      exitBeforeEnter
      initial={false}
      onExitComplete={() => {
        window.scrollTo(0, 0)
      }}
    >
      <div className={styles['bubble-container']} key={`${path}-container`}>
        <motion.svg
          animate={{
            opacity: [...new Int8Array(6).fill(1), 0],
            scale: [...new Int8Array(6).fill(1), 0.9]
          }}
          className={styles.logo}
          exit={{
            opacity: [...new Int8Array(2).fill(0), ...new Int8Array(4).fill(1)],
            scale: 1
          }}
          fill="none"
          height="84"
          initial={{
            opacity: 1,
            scale: 1
          }}
          key={`${path}-logo`}
          transition={{ duration: transitionDuration }}
          viewBox="0 0 89 84"
          width="89"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask height="46" id="a" maskUnits="userSpaceOnUse" width="38" x="0" y="0">
            <path d="M6.785 45.352c8.325 0 14.437-12.541 16.922-16.976 2.431-4.34 6.52-12.12 11.626-15.518l1.981-9.19A2.959 2.959 0 0 0 34.44 0H8.501a4.443 4.443 0 0 0-4.316 3.4C1.44 14.754 0 24.47 0 31.74c0 7.054 2.348 13.612 6.785 13.612Z" fill="#fff"/>
          </mask>
          <g mask="url(#a)"><path d="M-24-22h79v70h-79z" fill="#fff"/></g>
          <mask height="35" id="b" maskUnits="userSpaceOnUse" width="36" x="12" y="19">
            <path d="M47.543 27.203c0-3.895-1.296-8.086-4.142-8.086-4.329 0-8.03 6.605-11.947 13.6-2.728 4.87-5.548 9.906-9.207 13.929-2.144 2.358-5.311 5.201-9.691 6.639h19.926a5.917 5.917 0 0 0 5.044-2.822c.61-.994 1.226-1.975 1.836-2.948 4.208-6.7 8.18-13.029 8.18-20.312Z" fill="#fff"/>
          </mask>
          <g mask="url(#b)"><path d="M-15 18h79v70h-79z" fill="#fff"/></g>
          <mask height="73" id="c" maskUnits="userSpaceOnUse" width="51" x="38" y="11">
            <path d="M77.913 11.989H49.569c4.543 2.847 6.854 8.626 6.854 15.214 0 9.84-4.85 17.564-9.54 25.034-4.225 6.73-8.216 13.087-8.216 20.44v7.397a2.96 2.96 0 0 0 2.96 2.96h26.458a2.96 2.96 0 0 0 2.96-2.96v-6.877c0-16.937 17.761-26.738 17.761-46.16 0-9.377-4.616-15.048-10.893-15.048Z" fill="#fff"/>
          </mask>
          <g mask="url(#c)"><path d="M37 7h79v82H37z" fill="#fff"/></g>
        </motion.svg>

        <motion.div
          animate={{
            scale: [...new Int8Array(3).fill(3), 0],
            backgroundColor: [...Array.from({ length: 4 }).fill('#31373D'), '#11171D'] as string[],
            y: [...Array.from({ length: 3 }).fill('0%'), '-100%'] as string[]
          }}
          className={styles.bubble}
          exit={{
            scale: [0, ...new Int8Array(3).fill(3)],
            backgroundColor: ['#11171D', '#31373D'],
            y: ['100%', ...Array.from({ length: 3 }).fill('0%')] as string[]
          }}
          initial={{
            scale: 4,
            bottom: 0
          }}
          onAnimationComplete={(ev: { scale?: number[]}) => {
            if (ev.scale?.[0] === 3) {
              unlock()
            }
          }}
          onAnimationStart={lock}
          transition={{
            ease: 'easeIn',
            duration: transitionDuration
          }}
        />
      </div>
      <motion.div
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        key={`${path}-children`}
        transition={{ duration: transitionDuration }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}
