import React from 'react'

// Components

// Types
export interface GridProps {
  cols?: number
  size?: string
  gap?: string
  align?: boolean
  removePadding?: boolean
  removePaddingTablet?: boolean
  as?: React.ElementType
  className?: string
  style?: React.CSSProperties
}

const Grid: React.FC<GridProps> = ({
  cols = 12,
  children,
  size = 'l',
  gap,
  align,
  style,
  removePadding,
  removePaddingTablet,
  as = 'div',
  className = '',
  ...props
}) => {
  const properties = {
    'data-grid': true,
    'data-container-size': size,
    ...(cols ? { 'data-cols': cols } : {}),
    ...(align ? { 'data-align': true } : {}),
    ...(removePadding ? { 'data-remove-padding': true } : {}),
    ...(removePaddingTablet ? { 'data-remove-tablet-padding': true } : {}),
    ...(gap ? { 'data-gap': gap } : {}),
    style: {
      ...style,
      ...(gap ? { '--gap': gap } : {}),
      ...(align ? { '--align': align } : {})
    } as React.CSSProperties
  }

  const Component = as

  return (
    <Component
      {...properties}
      className={className}
      {...props}
    >
      {children}
    </Component>
  )
}

Grid.displayName = 'Grid'

export default Grid

export { default as Col } from './Col'
